import axios from "axios";

export const login = async (cred) => {
  return await axios.post("/api/auth/authenticate", cred).then((res) => {
    return res;
  });
};

export const logout = () => {
  sessionStorage.removeItem("cv_tagging");
  window.location.reload();
};

export const getToken = () => {
  const token = sessionStorage.getItem("cv_tagging");
  if (token) {
    return JSON.parse(token);
  }
  return false;
};
